body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
  background-color: #fcfcfc;
}

.w-100 {
  width: 100% !important;
}

.pointer {
  cursor: pointer !important;
}

.inter {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.inter-xs {
  font-family: "Inter", sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
}

.inter-sm {
  font-family: "Inter", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.inter-md {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.inter-lg {
  font-family: "Inter", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.inter-xl {
  font-family: "Inter", sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.inter-xxl {
  font-family: "Inter", sans-serif !important;
  font-size: 28px !important;
  font-weight: 400 !important;
}

.inter-xxxl {
  font-family: "Inter", sans-serif !important;
  font-size: 34px !important;
  font-weight: 400 !important;
}

.inter-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.inter-sm-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.inter-md-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.inter-lg-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.inter-xl-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.inter-xxl-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.inter-xxxl-bold {
  font-family: "Inter", sans-serif !important;
  font-size: 34px !important;
  font-weight: 700 !important;
}

.loginButton-parent {
  display: flex;
  background-image: url('./assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  height: 100vh;
}

.loginButton {
  width: 200px;
  height: 50px;
  background-color: antiquewhite;
}

.login-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.d-flex {
  display: flex !important;
}

.text-white {
  color: white !important;
}

.text-red {
  color: red !important;
}

.circle-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgb(245, 245, 245);
}

.dynamic-island {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.notification-count {
  position: relative;
  top: 4px;
  left: 0px;
}

.text-grey {
  color: grey !important;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .login-card {
    width: 90%;
  }

  .dynamic-island {
    border: none !important;
    border-radius: 0;
  }
}