.profile-card {
  border-radius: 8px;
}

.profile-pic-container {
  height: 100%;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-top-heart {
  color: #ccc;
}
