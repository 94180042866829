/* .swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal */
.swiper-pagination {
  position: absolute !important;
  bottom: 15px !important;
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}

.swiper-pagination-bullet {
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background-color: red !important;
}

@media screen and (max-width: 768px) {
  .swiper-pagination {
    position: fixed !important;
    top: 470px !important;
  }

  .cc-logo {
    width: 50px !important;
  }

  .swiper-pagination-bullet {
    height: 7px;
    width: 7px;
  }
}