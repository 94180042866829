.select-card{
  border: 1px solid grey !important;
  padding: 25px;
  border-radius: 15px;
  cursor: pointer;
  transition: border 0.3s ease; /* Smooth transition for the border */
  margin-bottom: 10px;
}
.banner-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping */
  gap: 10px; /* Adds space between the items */
}

.select-banner-card {
  border: 1px solid #B0BEC5;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: border 0.3s ease;
  font-size: 16px;
  display: inline-flex; /* Makes sure the card is sized appropriately */
  justify-content: center;
  align-items: center;
}

.select-banner-card.active{
  box-shadow: 0 0 10px rgba(250, 107, 107, 0.414);
  background-color: rgba(246, 151, 151, 0.071) !important;
  color: red;
  border: 1px solid #ce1717  !important;
}
.select-banner-card:hover {
  /* border: 1px solid red !important; */
  background-color: rgba(246, 181, 181, 0.235) !important;
}

.input-onboarding {
  border: 1px solid grey !important;
  padding: 20px;
  border-radius: 15px;
  transition: border 0.3s ease; /* Smooth transition for the border */
  margin-bottom: 10px;
  width: 100%;
}

.select-card:hover {
  background-color: rgba(246, 181, 181, 0.235) !important;
}

.select-card.active{
  box-shadow: 0 0 10px rgba(250, 107, 107, 0.414);
  background-color: rgba(246, 151, 151, 0.071) !important;
  color: red;
  border: 1px solid #ce1717  !important;
}

.btn-primary{
  background-color: #e53c45 !important;
  border-radius: 25px;
  padding: 25px;
  border: none !important;
  color: white !important;
  width: 100%;
}

.text-scratch {
  position: relative;
  display: inline-block;
  color: rgb(37, 33, 33); /* Text color */
}

.line {
  position: absolute;
  height: 1.5px;
  background: rgb(43, 43, 43);
  left: 0;
  right: 0;
}

.line:nth-child(2) { top: 35%; rotate: 1deg; }
.line:nth-child(3) { top: 50%; rotate: 5deg;}
.line:nth-child(4) { top: 65%;  rotate: 178deg; }
.line:nth-child(5) { top: 65%;  rotate: 174deg; }

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}
.ant-picker.ant-picker-range span.ant-picker-suffix:hover{
  display: block !important;
}