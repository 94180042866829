@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.profile-card {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 370px;
  height: 350px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-container {
  position: relative;
}

.profile-top-details{
  color:#468499;
  display: flex;
  justify-content: space-between;
}

.profile-top-heart{
  color:#468499;
  color: grey;
  transition: color 0.3s ease;

}
.profile-top-heart:hover{
  color:#468499;  
}
.heart-icon.clicked {
  animation: pulse 0.3s ease;
}
.profile-details {
  line-height: 1.8; /* Adjust line height for spacing */
  display: flex;
  flex-direction: column;
}
.profile-pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.request-button {
  width: 100%; /* Align width with image */
  background-color: #468499; /* Custom color */
  border: none;
  position: relative;
  bottom: 0;
}


.profile-pic {
  width: 150px; /* Square dimensions */
  height: 150px;
  object-fit: cover; /* Ensures the image covers the square without distortion */
  border-radius: 8px; /* Slightly rounded corners */
  margin-bottom: 10px;
}

.profile-matching-title {
  margin-bottom: 10px !important;
  color: #468499 !important;
  font-family: "Playfair Display", serif !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

.profile-image {
  height: 300px;
  width: 100%;
  object-fit: contain;
  object-position: center top;
}
.profile-bio{
  font-style: italic;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}

.profile-name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.profile-location {
  margin: 5px 0;
  font-size: 14px;
  color: #d3d3d3;
}

.profile-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.action-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cancel-button {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
}

.like-button {
  background-color: rgba(0, 0, 255, 0.8);
  color: white;
  border: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .profile-card {
    width: 100%;
    height: auto;
    user-select: none;
  }
  .profile-pic {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    -webkit-tap-highlight-color: transparent
  }
}