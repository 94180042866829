/* General Layout */
.layout {
  min-height: 100vh;
  /* background-color: #fff; */
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: #f5f5f5;
  margin-top: 15px;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.user-name {
  font-size: 25px;
  margin-right: auto;
}

.search-bar {
  width: 150px;
  margin-left: 16px;
}

.avatar-icon {
  margin-left: 16px;
  height: 40px;
  width: 40px;
}

/* Profile Card */
.profile-card {
  display: flex;
  width: 357px;
  justify-content: center;
  align-items: start;
  height: 100%;
  margin: auto;
  margin-top: 20px;
}

.profile-details {
  display: flex;
  position: relative;
  align-items: start;
  justify-content: flex-start;
  bottom: 160px;
  color: #f5f5f5;
  left: 37px;
  width: 100%;
}

.profile-name {
  position: relative;
  right: 0px;
  bottom: 12px;
  color: #f5f5f5;
  font-family: "Inter", sans-serif !important;
  font-size: 34px !important;
  text-align: left;
  font-weight: 600 !important;
}

.profile-age {
  position: relative;
  color: #f5f5f5;
  font-family: "Inter", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 10px;
  text-align: right;
  left: 8px;
}

.profile-image {
  margin-top: 10px;
  height: 500px !important;
  width: 357px !important;
  margin-bottom: 16px;
}

.profile-info {
  text-align: center;
}

.complete-profile-btn {
  margin-top: 16px;
}

.profile-tags {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.tag {
  background-color: #e6e6e6;
  padding: 4px 8px;
  border-radius: 8px;
  margin: 0 4px;
  font-size: 12px;
}

/* Waiting message */
.waiting-message {
  text-align: center;
  padding: 16px;
}

/* Footer */
.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.ant-tabs-tab {
  font-size: 24px;
}

.custom-modal .ant-modal-content {
  border-radius: 20px !important;
  /* Ensure the border radius applies */
}

.custom-modal .ant-modal-body {
  text-align: center;
  /* Ensure the content inside is centered */
}